import { action } from '@ember/object';
import { scheduleOnce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import babelEnv from 'babel/config/environment';
import { scrollTo } from 'babel/utils/chapter-viewport';
import { trackReadingBuddyEvent } from 'babel/utils/matamo-events';
import { storageFor } from 'ember-local-storage';

const STATES = {
  paused: 'paused',
  listening: 'listening',
  speaking: 'speaking',
  notStarted: 'notStarted',
  ended: 'ended',
};

export default class ReadingBuddyComponent extends Component {
  azureApiTokenEndpoint = babelEnv.talkamatic?.azureApiTokenEndpoint;
  speechRecognitionEndpointId =
    babelEnv.talkamatic?.speechRecognitionEndpointId;
  rootURL = babelEnv.rootURL;

  sendInitialTurnPage = true;
  subscription = null;

  @service intl;

  @tracked currentState = STATES.notStarted;
  @tracked ttsLexicon =
    'https://cvoiceprodneu.blob.core.windows.net/acc-public-files/33867cc7d3ce43b9a040dc16212a025e/9f6c85e1-4fe9-4268-b337-24015d30b46c.xml';
  @tracked isActivated = false;
  @tracked isLoading = false;
  @tracked isMinimized = false;
  @tracked intervalId = null;

  @storageFor('reading-buddy') settings;

  get avatar() {
    return `/assets/images/reading-buddy/dog_${this.currentState}.svg`;
  }

  get displayText() {
    return this.intl.t(`components.readingBuddy.states.${this.currentState}`);
  }

  get readingBuddyClass() {
    return this.inPauseState ? 'reading-buddy-paused' : 'reading-buddy-playing';
  }

  get readingBuddyActiveClass() {
    return this.isActivated ? ' reading-buddy-activated' : '';
  }

  get inPauseState() {
    return this.isPaused || !this.isActivated;
  }

  get isListening() {
    return this.currentState === STATES.listening;
  }

  get isSpeaking() {
    return this.currentState === STATES.speaking;
  }

  get isPaused() {
    return this.currentState === STATES.paused;
  }

  get isEnded() {
    return this.currentState === STATES.ended;
  }

  // Actions

  @action
  handleMinimize() {
    this.reset();
    this.isMinimized = true;
    this.settings.set('isMinimized', true);
    trackReadingBuddyEvent('Minimera');
  }

  @action
  handleMaximize() {
    this.isMinimized = false;
    this.settings.set('isMinimized', false);
    trackReadingBuddyEvent('Maximera');

    scheduleOnce('afterRender', this, this.#scrollToReadingBuddyIfNeeded);
  }

  @action
  handleRestart() {
    // This will also be called from stopped event in state listener, no idea why we need both
    this.reset();
  }

  @action
  handleClick() {
    this.isLoading = true;
    if (!this.isActivated || this.isEnded) {
      this.activate();
    } else {
      this.isPaused ? this.activate() : this.dispatchPause();
    }
  }

  @action
  end() {
    this.currentState = STATES.ended;
    this.isActivated = false;
  }

  @action
  resetPage() {
    this.dispatchTurnPage();
    if (this.args.resetOnPageChange) this.reset();
  }

  // Functions

  #scrollToReadingBuddyIfNeeded() {
    const elem = document.querySelector('.reading-buddy-container');

    if (elem && !elem.classList.contains('reading-buddy-activated')) {
      scrollTo(elem, 'smooth');
    }
  }

  dispatchPause() {
    trackReadingBuddyEvent('Paus');
    window.TalaSpeech.send({ type: 'CONTROL' });
  }

  dispatchActivate() {
    trackReadingBuddyEvent('Aktivera');
    this.isPaused
      ? window.TalaSpeech.send({ type: 'CONTROL' })
      : window.TalaSpeech.send({ type: 'START' });
  }

  dispatchPrepare() {
    window.TalaSpeech.send({ type: 'PREPARE' });
  }

  dispatchStop() {
    trackReadingBuddyEvent('Stop');
    window.TalaSpeech.send({ type: 'STOP' });
  }

  dispatchTurnPage() {
    this.isLoading = true;
    if (this.isActivated) {
      this.dispatchStop();
      this.isActivated = false;
    }
    trackReadingBuddyEvent('Vänd blad');
    window.TalaSpeech.send({ type: 'TURN_PAGE', value: this.args.page });
  }

  activate() {
    this.isActivated = true;
    this.dispatchActivate();
  }

  reset() {
    if (this.isActivated) {
      this.dispatchStop();
      this.isActivated = false;
    }

    this.sendInitialTurnPage = true;

    this.setup();
    this.currentState = STATES.notStarted;
    this.isLoading = false;
  }

  @action
  stateListener(snapshot) {
    switch (window.TalaSpeechUIState) {
      case 'before-prepare':
        this.dispatchPrepare();
        break;
      case 'speaking':
        this.isActivated = true;
        this.currentState = STATES.speaking;
        this.isLoading = false;
        break;
      case 'ready':
        if (snapshot.context?.segment === 'cover' && this.sendInitialTurnPage) {
          this.isLoading = true;
          this.dispatchTurnPage();
          this.sendInitialTurnPage = false;
          break;
        }
        this.isLoading = false;
        break;
      case 'recognising-paused':
      case 'speaking-paused':
        this.currentState = STATES.paused;
        this.isLoading = false;
        break;
      case 'recognising':
        this.currentState = STATES.listening;
        this.isLoading = false;
        break;
      case 'end':
        this.end();
        break;
      case 'stopped':
        this.reset();
        break;
      case 'initiating':
        this.isLoading = true;
        break;
      default:
        console.log('Unknown state', window.TalaSpeechUIState);
    }
  }

  setup() {
    if (!this.subscription) {
      return;
    }

    window.TalaSpeech.send({
      type: 'SETUP',
      value: {
        deviceID: 'digilar-production-20230124',
        endpoint: this.args.endpoint,
        azureCredentials: this.azureApiTokenEndpoint,
        azureRegion: 'swedencentral',
        locale: 'sv-SE',
        speechRecognitionEndpointId: this.speechRecognitionEndpointId,
        ttsDefaultVoice: 'sv-SE-SofieNeural',
      },
    });
    this.isLoading = true;
  }

  waitForTalkomatic() {
    if (this.intervalID) {
      return;
    }

    this.intervalID = setInterval(() => {
      if (window.TalaSpeech) {
        clearInterval(this.intervalID);
        this.subscription = window.TalaSpeech.subscribe(this.stateListener);
        this.setup();
      }
    }, 100);
  }

  // Life cycle hooks

  constructor() {
    super(...arguments);
    this.waitForTalkomatic();
    this.isMinimized = this.settings.get('isMinimized') ?? false;
    trackReadingBuddyEvent('Visas');
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.dispatchStop();
    this.subscription?.unsubscribe();
  }
}
